import React, { Component } from 'react';
import Lightbox from 'react-images';
import Gallery from 'react-photo-gallery';

import data from '../data/data.json';

class PhotoGallery extends Component {
  constructor({ match }) {
    super();
    this.state = { currentImage: 0 };
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);

    const gallery = data.galerija.slike;

    this.state = {
      gallery,
    };
  }

  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }

  render() {
    return (
      <section id="events_gallery">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="gallery_title">
                <h2>Foto galerija</h2>
                <hr className="dotted_divider_center_red" />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="gallery_content">
                <div className="row">
                  <div className="gallery-items-wrapper gallery-grid-5 col-lg-12">
                    <div className="gallery-items" id="gallery-masonry">
                      <div className="gallery-item" title="Sabrage">
                        {/* <figure className="gallery-image"> */}
                        <Gallery photos={this.state.gallery} onClick={this.openLightbox} />
                        <Lightbox
                          images={this.state.gallery}
                          onClose={this.closeLightbox}
                          onClickPrev={this.gotoPrevious}
                          onClickNext={this.gotoNext}
                          currentImage={this.state.currentImage}
                          isOpen={this.state.lightboxIsOpen}
                        />
                        {/* </figure> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default PhotoGallery;
