export const createEvent = event => (dispatch, getState, { getFirebase, getFirestore }) => {
  // call db
  const firestore = getFirestore();
  const profile = getState().firebase.profile;
  const authorId = getState().firebase.auth.uid;
  firestore
    .collection('events')
    .add({
      ...event,
      authorFirstName: profile.firstName,
      authorLastName: profile.lastName,
      authorId,
    })
    .then(() => {
      dispatch({
        type: 'CREATE_EVENT',
        event,
      });
    })
    .catch(err => {
      dispatch({
        type: 'CREATE_EVENT_ERROR',
        err,
      });
    });
};
