import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Datetime from 'react-datetime';
import firebase from 'firebase/app';
import 'firebase/storage';
import FileUploader from 'react-firebase-file-uploader';

import { createEvent } from '../store/actions/eventActions';
import './CreateEventDateTimePicker.css';

class CreateEvent extends Component {
  state = {
    title: '',
    date: '',
    body: '',
    image: '',
    fileName: '',
    isUploading: false,
    progress: 0,
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handleDate = e => {
    this.setState({
      date: e._d,
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.createEvent(this.state);
    this.props.history.push('/dogadjanja');
  };
  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };
  handleUploadSuccess = filename => {
    this.setState({ fileName: filename, progress: 100, isUploading: false });
    firebase
      .storage()
      .ref('images')
      .child(filename)
      .getDownloadURL()
      .then(url => this.setState({ image: url }));
  };

  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to="/" />;
    return (
      <section id="contact_us">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
              <div className="contact_info_card">
                <div className="contact_title">
                  <h2>Novi event!</h2>
                  <hr className="dotted_divider_center_red" />
                </div>

                <div className="contact_form">
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-row">
                      <div className="col-md-6 col-sm-12 mb-3">
                        <label htmlFor="date">Datum</label>
                        <Datetime id="date" inputProps={{ placeholder: 'odaberi datum' }} onChange={this.handleDate} />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-12 mb-3">
                        <label htmlFor="title">Naslov</label>
                        <input
                          type="text"
                          className="form-control"
                          id="title"
                          placeholder="title"
                          onChange={this.handleChange}
                          required
                        />
                        <div className="invalid-feedback">naslov je obavezan</div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-12 mb-3">
                        <label htmlFor="body">Sadržaj</label>
                        <textarea
                          className="form-control"
                          id="body"
                          placeholder="body"
                          onChange={this.handleChange}
                          required
                          rows="5"
                        />
                        <div className="invalid-feedback">body je obavezan</div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-md-12 mb-3">
                        <label htmlFor="image">Image</label>
                        <input
                          type="text"
                          className="form-control"
                          id="image"
                          placeholder="image"
                          onChange={this.handleChange}
                        />
                        <br />
                        <div className="form-control">
                          Sliku prvo uploadaj ovdje:{' '}
                          <a
                            href="https://console.cloud.google.com/storage/browser/images.sabragebar.com?project=sabragebar-1525867510786"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            GOOGLE BUCKET
                          </a>
                        </div>
                        <div className="invalid-feedback">Slika nije obavezna</div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-sm-12 mb-3">
                        {this.state.isUploading && <p>Progress: {this.state.progress}%</p>}
                        {/* {this.state.image && <img src={this.state.image} alt="" />} */}
                        <label
                          htmlFor="fileName"
                          style={{
                            backgroundColor: 'steelblue',
                            color: 'white',
                            padding: 10,
                            borderRadius: 4,
                            cursor: 'pointer',
                          }}
                        >
                          Auto "upload & resize" slike na server. &rarr;
                          <FileUploader
                            hidden
                            accept="image/*"
                            name="fileName"
                            id="fileName"
                            randomizeFilename
                            storageRef={firebase.storage().ref('images')}
                            onUploadStart={this.handleUploadStart}
                            onUploadError={this.handleUploadError}
                            onUploadSuccess={this.handleUploadSuccess}
                            onProgress={this.handleProgress}
                            maxWidth="1200"
                            maxHeight="800"
                            className="form-control"
                          />
                        </label>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-sm-12 text-center mb-3">
                        {this.state.image && <img src={this.state.image} alt="" className="img-fluid" />}
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-sm-12 text-center">
                        <input className="btn btn-full-sabrage-sm" type="submit" value="Kreiraj novi event!" />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth,
});

const mapDispatchToProps = dispatch => ({
  createEvent: event => dispatch(createEvent(event)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEvent);
