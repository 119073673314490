import React from 'react';
import { limitWords } from '../helpers/helpers';

const style = {
  backgroundColor: '',
  color: '',
};

const AboutCardLeft = props => (
  <div className="about_card" style={style}>
    <div className="row no-padding">
      <div className="col-xl-6 col-lg-12 no-padding">
        <div className="card_content" style={style}>
          <h2>{props.bar.title}</h2>
          <hr className="dotted_divider_left_red" />
          <p>{props.bar.desc.split(' ').length > 16 ? `${limitWords(props.bar.desc, 16)}...` : props.bar.desc}</p>
          <a href={props.bar.ctaLink} className="btn btn-link-sabrage-sm">
            {props.bar.ctaTitle}
            <i className="fas fa-long-arrow-alt-right" aria-hidden="true" />
          </a>
        </div>
      </div>
      <div className="col-xl-6 col-lg-12 no-padding">
        <div className="specials_img">
          <a href={props.bar.ctaLink}>
            <img
              className="img-fluid mx-auto d-block"
              src="/images/_bg/sq-08.jpg"
              width="600"
              height="600"
              alt={props.bar.title}
            />
          </a>
        </div>
      </div>
    </div>
  </div>
);
export default AboutCardLeft;
