import React, { Component } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

import './App.css';
import './Style.css';
import SpecialsHeader from './components/SpecialsHeader';
import Specials from './components/Specials';
import About from './components/About';
import EventModule from './components/EventModule';
import Eventsticker from './components/Eventsticker';
// import EventsGradina from './components/Events2';

import data from './data/data.json';

class App extends Component {
  state = {
    ponuda: data.posebna_ponuda,
    site: data.site,
    program: data.site.onama[4],
  };

  render() {
    const { events } = this.props;
    const today = new Date().getTime() / 1000;
    const nextEvents = events && events.filter(e => e.date.seconds >= today);
    const nextEvent = nextEvents && nextEvents[0];

    return (
      <div>
        {events && events ? (
          events.filter(e => e.date.seconds >= today).map(event => <Eventsticker events={events} key={event.id} />)
        ) : (
          <p>Učitavam...</p>
        )}
        <SpecialsHeader ponuda={this.state.ponuda} site={this.state.site} />
        <About site={this.state.site} />
        {nextEvent && nextEvent ? <EventModule event={nextEvent} /> : null}
        <Specials program={this.state.program} />
        {/* <EventsGradina events={this.state.events} /> */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  events: state.firestore.ordered.events,
  auth: state.firebase.auth,
});

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: 'events', limit: 10, orderBy: ['date', 'asc'] }])
)(App);
