import React, { Component } from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import SanitizedHTML from 'react-sanitized-html';
import Loader from './Loader';

const style = {
  padding: '0 20px',
};

class ArticlePage extends Component {
  state = {
    pageId: this.props.match.params.slug,
    article: '',
  };

  componentDidMount() {
    this.getArticles();
  }

  async getArticles() {
    const response = await axios.get(`https://www.trsatskagradina.com/wp-json/wp/v2/posts?_embed`);
    const data = await response.data;
    const article = await data.find(({ slug }) => slug === this.state.pageId);
    this.setState({ article });
  }

  render() {
    const item = this.state.article;

    return item ? (
      <div>
        <section id="blog_single">
          <div className="container">
            <div className="col-xl-10 offset-xl-1 col-md-12 col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h2 className="card-title">
                    <SanitizedHTML html={item.title.rendered} />
                  </h2>
                  <br />
                  {<Moment format="DD/MM/YYYY">{item.date}</Moment>}
                </div>
                {item._embedded['wp:featuredmedia'] ? (
                  <img
                    className="card-img-top img-fluid mx-auto"
                    src={item._embedded['wp:featuredmedia']['0'].source_url || ''}
                    alt={item.title.rendered}
                  />
                ) : null}
                <div className="card-body">
                  <div className="row">
                    <div className="card-text-big" style={style}>
                      <SanitizedHTML html={item.content.rendered} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    ) : (
      <Loader />
    );
  }
}
export default ArticlePage;
