import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { signUp } from '../store/actions/authActions';

class SignUp extends Component {
  state = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.signUp(this.state);
    this.props.history.push('/');
  };
  render() {
    const { auth, authError } = this.props;
    if (!auth.uid) return <Redirect to="/" />;
    return (
      <section id="contact_us">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
              <div className="contact_info_card">
                <div className="contact_title">
                  <h2>Novi korisnik!</h2>
                  <hr className="dotted_divider_center_red" />
                </div>

                <div className="contact_form">
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-row">
                      <div className="col-md-12 mb-3">
                        <label htmlFor="firstName">Ime</label>
                        <input
                          type="text"
                          className="form-control"
                          id="firstName"
                          placeholder="Ime"
                          onChange={this.handleChange}
                          required
                        />
                        <div className="invalid-feedback">Ime je obavezno</div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-12 mb-3">
                        <label htmlFor="lastName">Prezime</label>
                        <input
                          type="text"
                          className="form-control"
                          id="lastName"
                          placeholder="Prezime"
                          onChange={this.handleChange}
                          required
                        />
                        <div className="invalid-feedback">Prezime je obavezno</div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-12 mb-3">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="email"
                          onChange={this.handleChange}
                          required
                        />
                        <div className="invalid-feedback">Email je obavezan</div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-12 mb-3">
                        <label htmlFor="password">Lozinka</label>
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          placeholder="Password"
                          onChange={this.handleChange}
                          required
                        />
                        <div className="invalid-feedback">Lozinka je obavezna</div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-sm-12 text-center">
                        <input className="btn btn-full-sabrage-sm" type="submit" value="Kreiraj novog korisnika!" />
                      </div>
                      {authError ? <p>{authError}</p> : null}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  authError: state.auth.authError,
});

const mapDispatchToProps = dispatch => ({
  signUp: newUser => dispatch(signUp(newUser)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUp);
