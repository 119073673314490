import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { logOut } from '../store/actions/authActions';

const Navigation = props => {
  const { auth } = props;
  const links = auth.uid ? (
    <span>
      <li className="nav-item" style={{ cursor: 'pointer' }}>
        <Link className="nav-link" to="/novi-event">
          Novi događaj
        </Link>
      </li>
      <li className="nav-item" style={{ cursor: 'pointer' }}>
        <Link className="nav-link" to="/signup">
          Novi korisnik
        </Link>
      </li>
      <li className="nav-item" style={{ cursor: 'pointer' }}>
        <a className="nav-link" onClick={props.logOut}>
          Log Out
        </a>
      </li>
    </span>
  ) : null;
  return (
    <div className="fixed-top">
      <nav id="main_nav" className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            Sabrage Bar
          </a>
          <button
            className="custom-toggler navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ml-auto">
              {props.navigation.map(nav => (
                <li className="nav-item" key={nav.url}>
                  <NavLink to={nav.url} exact activeClassName="active" className="nav-link">
                    {nav.title}
                  </NavLink>
                </li>
              ))}
              {links}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
});

const mapDispatchToProps = dispatch => ({
  logOut: () => dispatch(logOut()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navigation);
