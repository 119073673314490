import React from 'react';

const Page404 = () => (
  <section id="specials01">
    <div className="container">
      <div className="row">
        <div className="col-xl-12">
          <div className="specials_wrap text-center">
            <div className="row">
              <div className="col-lg-6 col-md-12 no-padding">
                <div className="specials_img">
                  <img src="/images/_bg/sq-01.jpg" width="1000" height="1000" className="img-fluid mx-auto" alt="" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 no-padding align-self-center">
                <div className="specials_content">
                  <h2>Page 404</h2>
                  <hr className="dotted_divider_center_red" />
                  <p>Žao nam je, ali ova stranica ne postoji...</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Page404;
