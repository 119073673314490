import React from 'react';

const ThankYou = () => (
  <section id="special_orders">
    <div className="container">
      <div className="special_orders_content">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div className="order_card">
              <div className="row no-padding">
                <div className="col-md-6 no-padding">
                  <div className="card_content">
                    <h2>Hvala vam na poruci...</h2>
                    <hr className="dotted_divider_left_red" />
                    <p>Odgovoriti ćemo vam u najkracem vremenu...</p>
                  </div>
                </div>
                <div className="col-md-6 no-padding">
                  <div className="specials_img">
                    <img
                      className="img-fluid mx-auto d-block"
                      src="/images/_bg/sq-01.jpg"
                      width="1000"
                      height="1000"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default ThankYou;
