import React from 'react';
import ThankYou from './ThankYou';

const Contact = () =>
  document.referrer === 'https://formspree.io/info@sabragebar.com' ? (
    <ThankYou />
  ) : (
    <section id="contact_us">
      <div className="container">
        <div className="row">
          <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
            <div className="contact_info_card">
              <div className="contact_title">
                <h2>Javite nam se!</h2>
                <hr className="dotted_divider_center_red" />
              </div>

              <div className="contact_form">
                <form
                  action="https://formspree.io/info@sabragebar.com"
                  method="POST"
                  className="needs-validation"
                  noValidate
                >
                  <input type="hidden" name="_next" value="https://www.sabragebar.com" />
                  <input type="hidden" name="_subject" value="Sabragebar.com - 📧 📧 📧 - Nova poruka sa site-a!" />
                  <div className="form-row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom01">Ime</label>
                      <input
                        type="text"
                        className="form-control"
                        id="validationCustom01"
                        name="ime"
                        placeholder="Ime"
                        required
                      />
                      <div className="invalid-feedback">Ime je obavezno</div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom02">Prezime</label>
                      <input
                        type="text"
                        className="form-control"
                        id="validationCustom02"
                        name="prezime"
                        placeholder="Prezime"
                        required
                      />
                      <div className="invalid-feedback">Prezime je obavezno.</div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom03">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        id="validationCustom03"
                        name="_replyto"
                        placeholder="Email"
                        required
                      />
                      <div className="invalid-feedback">Email je obavezan.</div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom04">Telefon</label>
                      <input
                        type="text"
                        name="phone"
                        className="form-control"
                        id="validationCustom04"
                        placeholder="Telefon"
                        required
                      />
                      <div className="invalid-feedback">Telefon je obavezan.</div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label htmlFor="exampleFormControlTextarea1">Vaša poruka</label>
                        <textarea className="form-control" name="poruka" id="exampleFormControlTextarea1" rows="3" />
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-sm-12 text-center">
                      <input className="btn btn-full-sabrage-sm" type="submit" value="Send" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

export default Contact;
