import React from 'react';
import CocktailTop from './CocktailTop';
import CocktailBottom from './CocktailBottom';

import data from '../data/data.json';

const Blog = props => (
  <section id="blog" style={{ backgroundColor: '#eeefff' }}>
    <div className="container">
      <div className="blog_title">
        <div className="row">
          <div className="col-lg-12">
            <h2>Kokteli</h2>
            <hr className="dotted_divider_center_red" />
          </div>
        </div>
      </div>

      <div className="blog_posts">
        <div className="card-columns">
          {data.recepti.videos.map(
            cocktail =>
              cocktail.top ? (
                <CocktailTop single={cocktail} key={cocktail.slug} />
              ) : (
                <CocktailBottom single={cocktail} key={cocktail.slug}  />
              )
          )}
        </div>
      </div>
    </div>
  </section>
);

export default Blog;
