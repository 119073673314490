import React from 'react';

const CocktailBottom = props => (
  <div className="card" key={props.single.slug}>
    <a href={`/kokteli/${props.single.slug}`}>
      <img
        className="card-img-bottom img-fluid mx-auto d-block"
        src={props.single.ilustracija}
        width="600"
        height="600"
        alt={props.single.title}
      />
    </a>
    <div className="card-body">
      <h4 className="card-title">{props.single.title}</h4>
      <p className="card-text">{props.single.desc}</p>
      <p className="card-text">
        <a href={`/kokteli/${props.single.slug}`} className="btn btn-link-sabrage-sm">
          Detalji
        </a>
      </p>
    </div>
  </div>
);
export default CocktailBottom;
