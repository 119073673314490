import React, { Component } from 'react';
import data from '../data/data.json';

const style = {
  padding: '0 20px',
};

class AboutPage extends Component {
  constructor({ match }) {
    super();
    const page = data.site.onama.find(({ slug }) => slug === match.params.slug);

    this.state = {
      page,
    };
  }

  render() {
    return (
      <div>
        <section id="blog_single">
          <div className="container">
            <div className="col-xl-10 offset-xl-1 col-md-12 col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h2 className="card-title">{this.state.page.title}</h2>
                </div>
                <img className="card-img-top img-fluid mx-auto" src={this.state.page.img} alt={this.state.page.title} />
                <div className="card-body">
                  <div className="row">
                    <p className="card-text-big" style={style}>
                      {this.state.page.desc}
                    </p>
                  </div>
                </div>
                {/* <div className="social_share">
                  <div className="row">
                    <div className="col-sm-12">
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <a href="">
                            <i className="fab fa-facebook-square" aria-hidden="true" />
                            <br className="dontshow" />
                            <span className="dontshow">Facebook</span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="">
                            <i className="fab fa-twitter" aria-hidden="true" />
                            <br className="dontshow" />
                            <span className="dontshow">Twitter</span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="">
                            <i className="fab fa-google-plus" aria-hidden="true" />
                            <br className="dontshow" />
                            <span className="dontshow">Google Plus</span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="">
                            <i className="fab fa-pinterest-p" aria-hidden="true" />
                            <br className="dontshow" />
                            <span className="dontshow">Pinterest</span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="">
                            <i className="fab fa-linkedin" aria-hidden="true" />
                            <br className="dontshow" />
                            <span className="dontshow">Linkedin</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default AboutPage;
