/* eslint-disable */

import React, { Component } from 'react';
import YouTube from 'react-youtube';

import data from '../data/data.json';

const opts = {
  // height: '500',
  // width: '100%',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
  },
};

class CocktailPage extends Component {
  constructor({ match }) {
    super();
    const cocktails = data.recepti.videos;
    const cocktail = data.recepti.videos.find(({ slug }) => slug === match.params.slug);
    const prevCocktail = cocktails.find(element => element.eventKey === cocktail.eventKey - 1);
    const nextCocktail = cocktails.find(element => element.eventKey === cocktail.eventKey + 1);

    this.state = {
      navigation: data.navigation,
      kontakt: data.kontakt,
      cocktail,
      prevCocktail,
      nextCocktail,
    };
  }
  

  render() {
    return (
      <div>
        <section id="blog_single">
          <div className="container">
            <div className="col-xl-10 offset-xl-1 col-md-12 col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h2 className="card-title">{this.state.cocktail.title}</h2>
                  <p className="card-text-big">{this.state.cocktail.desc}</p>
                </div>
                <div className="embed-responsive embed-responsive-16by9">
                  <YouTube videoId={this.state.cocktail.ytid} opts={opts} onReady={this._onReady} className="embed-responsive-item" />
                </div>
                {/* <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <h4>What you need:</h4>
                      <hr className="dotted_divider_left_red" />
                      <table className="table table-bordered ingredients_table">
                        <thead>
                          <tr>
                            <th scope="col">Ingredients</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.cocktail.ingredients
                            ? this.state.cocktail.ingredients.map(ing => (
                                <tr>
                                <td>
                                    <p>{ing.ingredient}</p>
                                  </td>
                                </tr>
                              ))
                            : ''}
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-8">
                      <h4>How to make:</h4>
                      <hr className="dotted_divider_left_red" />

                      {this.state.cocktail.steps
                        ? this.state.cocktail.steps.map(step => (
                          <div>
                                <p className="step">
                              <i className="fa fa-utensil-spoon" /> {step.stepTitle}
                            </p>

                                <p className="card-text">{step.step}</p>
                              </div>
                          ))
                        : ''}
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="single_blog_nav">
                <div className="row">
                  <div className="col-6 d-flex justify-content-start">
                    <ul className="list-unstyled">
                      {this.state.prevCocktail ? (
                        <div>
                          <li className="direction">Prethodni koktel</li>
                          <li className="recipe_name">
                            <a href={this.state.prevCocktail.slug}>{this.state.prevCocktail.title}</a>
                          </li>
                        </div>
                      ) : (
                        ''
                      )}
                    </ul>
                  </div>
                  <div className="col-6 d-flex justify-content-end">
                    <ul className="list-unstyled text-right">
                      {this.state.nextCocktail ? (
                        <div>
                          <li className="direction">Sljedeći koktel</li>
                          <li className="recipe_name">
                            <a href={this.state.nextCocktail.slug}>{this.state.nextCocktail.title}</a>
                          </li>
                        </div>
                      ) : (
                        ''
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default CocktailPage;
