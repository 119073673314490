import React from 'react';
import OwlCarousel from 'react-owl-carousel';

const SpecialsThree = props => (
  <section id="specials01">
    <div className="container">
      <div className="row">
        <div className="col-xl-12">
          <div className="specials_wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12 no-padding align-self-center">
                <div className="specials_content">
                  <h2>{props.site.title}</h2>
                  <hr className="dotted_divider_left_red" />
                  <p>{props.site.desc}</p>
                  <a href={props.site.ctaLink} className="btn btn-link-sabrage-sm">
                    {props.site.ctaTitle} <i className="fas fa-long-arrow-alt-right" aria-hidden="true" />
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 no-padding">
                <OwlCarousel className="owl-theme" items={1} autoplay loop margin={0} dots={false}>
                  <div className="item specials_img">
                    <a href={props.site.ctaLink}>
                      <img src="/images/_bg/sq-05.jpg" className="img-fluid mx-auto" alt={props.site.title} />
                    </a>
                  </div>
                  <div className="item specials_img">
                    <a href={props.site.ctaLink}>
                      <img src="/images/_bg/sq-10.jpg" className="img-fluid mx-auto" alt={props.site.title} />
                    </a>
                  </div>
                  <div className="item specials_img">
                    <a href={props.site.ctaLink}>
                      <img src="/images/_bg/sq-11.jpg" className="img-fluid mx-auto" alt={props.site.title} />
                    </a>
                  </div>
                  <div className="item specials_img">
                    <a href={props.site.ctaLink}>
                      <img src="/images/_bg/sq-12.jpg" className="img-fluid mx-auto" alt={props.site.title} />
                    </a>
                  </div>
                  <div className="item specials_img">
                    <a href={props.site.ctaLink}>
                      <img src="/images/_bg/sq-14.jpg" className="img-fluid mx-auto" alt={props.site.title} />
                    </a>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="specials_list">
        <div className="row">
          {props.ponuda.map(item => (
            <div className="col-md-4" key={item.url}>
              <div className="img-wrap">
                <a href={item.url}>
                  <figure className="img_item">
                    <img className="img-fluid mx-auto" src={item.bgImg} alt={item.title} />
                    <figcaption>
                      <h3>{item.title}</h3>
                      <p>{item.desc}</p>
                    </figcaption>
                  </figure>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
);

export default SpecialsThree;
