import React, { Component, createRef } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';
import Datetime from 'react-datetime';
import firebase from 'firebase/app';
import 'firebase/storage';
import FileUploader from 'react-firebase-file-uploader';

import './CreateEventDateTimePicker.css';

class EditEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: '',
      fileName: '',
      isUploading: false,
      progress: 0,
    };
    this.titleInput = createRef();
    this.dateInput = createRef();
    this.bodyInput = createRef();
    this.imageInput = createRef();
    // this.fileNameInput = createRef();
    // this.isUploadingInput = createRef();
    // this.progressInput = createRef();
  }

  onSubmit = e => {
    e.preventDefault();
    const { event, firestore, history } = this.props;
    const updatedEvent = {
      title: this.titleInput.current.value,
      date: this.dateInput.current.state.selectedDate._d,
      body: this.bodyInput.current.value,
      image: this.state.image || this.imageInput.current.value,
      fileName: this.state.fileName,
      isUploading: this.state.isUploading,
      progress: this.state.progress,
    };

    firestore.update({ collection: 'events', doc: event.id }, updatedEvent).then(history.push('/'));
  };

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };
  handleUploadSuccess = filename => {
    this.setState({ fileName: filename, progress: 100, isUploading: false });
    firebase
      .storage()
      .ref('images')
      .child(filename)
      .getDownloadURL()
      .then(url => {
        console.log(url);
        this.setState({ image: url });
      });
  };

  render() {
    const { auth, event } = this.props;
    if (!auth.uid) return <Redirect to="/" />;

    if (event) {
      return (
        <section id="contact_us">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
                <div className="contact_info_card">
                  <div className="contact_title">
                    <h2>Izmjeni ovaj event!</h2>
                    <hr className="dotted_divider_center_red" />
                  </div>

                  <div className="contact_form">
                    <form onSubmit={this.onSubmit}>
                      <div className="form-row">
                        <div className="col-md-6 col-sm-12 mb-3">
                          <label htmlFor="date">Datum</label>
                          <Datetime
                            ref={this.dateInput}
                            id="date"
                            defaultValue={event.date.toDate()}
                            inputProps={{ placeholder: 'odaberi datum' }}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-md-12 mb-3">
                          <label htmlFor="title">Naslov</label>
                          <input
                            type="text"
                            className="form-control"
                            ref={this.titleInput}
                            id="title"
                            defaultValue={event.title}
                            placeholder="title"
                            required
                          />
                          <div className="invalid-feedback">naslov je obavezan</div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-md-12 mb-3">
                          <label htmlFor="body">Sadržaj</label>
                          <textarea
                            className="form-control"
                            ref={this.bodyInput}
                            id="body"
                            defaultValue={event.body}
                            placeholder="body"
                            required
                            rows="5"
                          />
                          <div className="invalid-feedback">Body je obavezno</div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="col-md-12 mb-3">
                          <label htmlFor="image">Image</label>
                          <input
                            type="text"
                            className="form-control"
                            ref={this.imageInput}
                            id="image"
                            defaultValue={event.image}
                            placeholder="image"
                          />
                          <br />
                          <div className="form-control">
                            Sliku prvo uploadaj ovdje:{' '}
                            <a
                              href="https://console.cloud.google.com/storage/browser/images.sabragebar.com?project=sabragebar-1525867510786"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              GOOGLE BUCKET
                            </a>
                          </div>
                          <div className="invalid-feedback">Slika nije obavezna</div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="col-sm-12 mb-3">
                          {this.state.isUploading && <p>Progress: {this.state.progress}%</p>}
                          <FileUploader
                            accept="image/*"
                            name="fileName"
                            randomizeFilename
                            storageRef={firebase.storage().ref('images')}
                            onUploadStart={this.handleUploadStart}
                            onUploadError={this.handleUploadError}
                            onUploadSuccess={this.handleUploadSuccess}
                            onProgress={this.handleProgress}
                            maxWidth="1200"
                            maxHeight="800"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="col-sm-12 text-center mb-3">
                          {event.image && <img src={event.image} alt="" className="img-fluid" />}
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="col-sm-12 text-center">
                          <input className="btn btn-full-sabrage-sm" type="submit" value="Izmjeni ovaj event!" />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }
    return <p>Loading...</p>;
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(props => [
    {
      collection: 'events',
      storeAs: 'event',
      doc: props.match.params.id,
    },
  ]),
  connect(({ firestore: { ordered } }, props) => ({
    event: ordered.event && ordered.event[0],
  })),
  connect(state => ({
    auth: state.firebase.auth,
  }))
)(EditEvent);
