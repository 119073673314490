import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import App from '../App';
import CocktailPage from '../components/CocktailPage';
import MainLayout from '../components/Layout';
import Cocktails from '../components/Cocktails';
import Gallery from '../components/Gallery';
import AboutPage from '../components/AboutPage';
import EventPage from '../components/EventPage';
import Events from '../components/Events';
import Contact from '../components/Contact';
import LogIn from '../components/LogIn';
import SignUp from '../components/SignUp';
import CreateEvent from '../components/CreateEvent';
import EditEvent from '../components/EditEvent';
import News from '../components/News';
import Onama from '../components/Onama';
import ArticlePage from '../components/ArticlePage';
import Page404 from '../components/404';

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Layout>
        <Component {...props} />
      </Layout>
    )}
  />
);

const AppRouter = () => (
  <Router>
    <Switch>
      <AppRoute exact path="/" layout={MainLayout} component={App} />
      <AppRoute exact path="/kokteli/:slug" layout={MainLayout} component={CocktailPage} />
      <AppRoute exact path="/kokteli" layout={MainLayout} component={Cocktails} />
      <AppRoute exact path="/o-nama/:slug" layout={MainLayout} component={AboutPage} />
      <AppRoute exact path="/o-nama" layout={MainLayout} component={Onama} />
      <AppRoute exact path="/vijesti/:slug" layout={MainLayout} component={ArticlePage} />
      <AppRoute exact path="/vijesti" layout={MainLayout} component={News} />
      <AppRoute exact path="/dogadjanja/:id" layout={MainLayout} component={EventPage} />
      <AppRoute exact path="/dogadjanja/" layout={MainLayout} component={Events} />
      <AppRoute exact path="/galerija" layout={MainLayout} component={Gallery} />
      <AppRoute exact path="/kontakt" layout={MainLayout} component={Contact} />
      <AppRoute exact path="/login" layout={MainLayout} component={LogIn} />
      <AppRoute exact path="/signup" layout={MainLayout} component={SignUp} />
      <AppRoute exact path="/novi-event" layout={MainLayout} component={CreateEvent} />
      <AppRoute exact path="/event/edit/:id" layout={MainLayout} component={EditEvent} />
      <AppRoute layout={MainLayout} component={Page404} />
    </Switch>
  </Router>
);

export default AppRouter;
