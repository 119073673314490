import React from 'react';

const Footer = ({ kontakt }) => (
  <footer className="dark_footer">
    <div className="container">
      <div className="footer_wrap">
        <div className="row">
          <div className="col-md-4" />
          <div className="col-md-4" />
          <div className="col-md-4">
            <div className="address">
              <h3>{kontakt.title}</h3>
              <p className="location">
                {kontakt.ulica}
                <br />
                {kontakt.grad}, {kontakt.drzava}
              </p>
            </div>
            <div className="hours">
              <div className="social">
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <a href={kontakt.instagram}>
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={kontakt.twitter}>
                      <i className="fab fa-twitter-square" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={kontakt.youtube}>
                      <i className="fab fa-youtube" />
                    </a>
                  </li>
                </ul>
              </div>
              <p className="email">
                Email: <a href={kontakt.emailto}>{kontakt.email}</a>
                <br />
                Telefon: <a href={kontakt.tel.callMobitel}>{kontakt.tel.mobitel}</a>
              </p>
              <p>
                OIB: {kontakt.oib}
                <br />
                IBAN: {kontakt.iban}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
