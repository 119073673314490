import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

let config = {};

if (process.env.NODE_ENV === 'development') {
  config = {
    apiKey: 'AIzaSyB9nlOYGxfV76foQUKUDtO0jP4GwD26AW4',
    authDomain: 'sabragedevelopement.firebaseapp.com',
    databaseURL: 'https://sabragedevelopement.firebaseio.com',
    projectId: 'sabragedevelopement',
    storageBucket: 'sabragedevelopement.appspot.com',
    messagingSenderId: '197708337204',
  };
} else if (process.env.NODE_ENV === 'production') {
  config = {
    apiKey: 'AIzaSyCVKs2DMwOM6Qx_mtptrdC4aBG9upbdLCA',
    authDomain: 'sabrage-8d3a2.firebaseapp.com',
    databaseURL: 'https://sabrage-8d3a2.firebaseio.com',
    projectId: 'sabrage-8d3a2',
    storageBucket: 'sabrage-8d3a2.appspot.com',
    messagingSenderId: '92054058047',
  };
}

// console.log('db: ', db);
// console.log('env: ', process.env.NODE_ENV);

firebase.initializeApp(config);
firebase.firestore().settings({ timestampsInSnapshots: true });

export default firebase;
