import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import moment from 'moment';
import 'moment/locale/hr';

import './Event.css';

const Event = ({ event, auth, firestore }) => {
  moment.locale('hr');
  const today = new Date().getTime() / 1000;

  const onDeleteClick = () => {
    firestore.delete({
      collection: 'events',
      doc: event.id,
    });
  };

  return (
    <div className="col-md-12">
      <div className="event_wrap event-link">
        <Link to={`/dogadjanja/${event.id}`}>
          <img
            src={event.image || '/images/_events/sabrage-temp.jpg'}
            className="img-fluid mx-auto"
            alt={event.title}
          />
          <h3 style={{ background: 'black', color: 'cyan' }}>
            {event.date.seconds >= today ? <span>NAJAVA: </span> : <span>ZAVRŠENO: </span>} {event.title} -{' '}
            {moment(event.date.toDate()).fromNow()}
          </h3>
        </Link>
        {auth && auth.uid ? (
          <div>
            <Link to={`/event/edit/${event.id}`} className="btn btn-primary">
              IZMJENI EVENT
            </Link>{' '}
            <input type="button" onClick={onDeleteClick} className="btn btn-danger" value="OBRIŠI EVENT" />
            <hr />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
});

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => [
    {
      collection: 'events',
      limit: 10,
      orderBy: ['date', 'asc'],
    },
  ])
)(Event);
