import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

import Event from './Event';

class Events extends Component {
  state = {};

  render() {
    const { events } = this.props;
    const today = new Date().getTime() / 1000;

    return (
      <section id="events">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="event_title">
                <h2>Događanja u Sabrage bar-u</h2>
                <hr className="dotted_divider_left_red" />
                <p />
              </div>
            </div>
            {events ? (
              events
                .filter(e => e.date.seconds >= today)
                // .sort((a, b) => a.date.seconds > b.date.seconds)
                .map(event => <Event event={event} key={event.id} />)
            ) : (
              <p>Učitavam...</p>
            )}
            <br />
            {events ? (
              events.filter(e => e.date.seconds <= today).map(event => (
                <div key={event.id}>
                  <hr className="dotted_divider_center_red" />
                  <Event event={event} />
                </div>
              ))
            ) : (
              <p>Učitavam...</p>
            )}
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  events: state.firestore.ordered.events,
});

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: 'events', limit: 10, orderBy: ['date', 'asc'] }])
)(Events);
