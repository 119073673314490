import React from 'react';

const Loader = () => (
  <section id="special_orders">
    <div className="container">
      <div className="special_orders_content">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="order_card">
              <div className="row no-padding">
                <div className="col-md-12 no-padding">
                  <div className="card_content">
                    <h1>Učitava se ...</h1>
                    {/* <hr className="dotted_divider_left_red" /> */}
                    <p>Malo strpljenja...</p>
                  </div>
                </div>
                <div className="col-md-12 no-padding">
                  <div className="specials_img">
                    <img
                      className="img-fluid mx-auto d-block"
                      src="/images/_bg/bg-00.jpg"
                      width="1000"
                      height="1000"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Loader;
