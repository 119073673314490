import React, { Component } from 'react';
import ajax from 'superagent';
import Moment from 'react-moment';
import SanitizedHTML from 'react-sanitized-html';
import Loader from './Loader';

class GradinaPosts extends Component {
  constructor() {
    super();

    this.state = {
      latestNews: null,
    };
  }

  componentWillMount() {
    this.getData();
  }

  getData() {
    ajax.get('https://www.trsatskagradina.com/wp-json/wp/v2/posts?_embed').end((error, response) => {
      if (!error && response) {
        this.setState({ latestNews: response.body });
      } else {
        console.log('There was an error fetching data from TrsatskaGradina.com WP JSON API', error);
      }
    });
  }

  render() {
    return (
      <section id="special_orders">
        <div className="container">
          <div className="special_orders_content">
            <div className="row">
              {this.state.latestNews ? (
                this.state.latestNews.map(article => (
                  <div className="col-md-6" key={article.id}>
                    <div className="order_card">
                      <div className="row no-padding">
                        <div className="col-md-12 no-padding">
                          <div className="card_content">
                            <a href={`/vijesti/${article.slug}`}>
                              <h2>
                                <SanitizedHTML html={article.title.rendered} />
                              </h2>
                            </a>
                            <Moment format="DD/MM/YYYY">{article.date}</Moment>
                            <hr className="dotted_divider_left_red" />
                            <SanitizedHTML html={article.excerpt.rendered} />
                          </div>
                        </div>
                        <div className="col-md-12 no-padding">
                          <div className="specials_img">
                            <a href={`/vijesti/${article.slug}`}>
                              {article._embedded['wp:featuredmedia'] ? (
                                <img
                                  className="img-fluid mx-auto d-block"
                                  src={article._embedded['wp:featuredmedia']['0'].source_url || ''}
                                  alt={article.title.rendered}
                                />
                              ) : null}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default GradinaPosts;
