import React from 'react';
import { limitWords } from '../helpers/helpers';

const CardLarge = props => (
  <div className="col-md-6">
    <div className="order_card">
      <div className="row no-padding">
        <div className="col-md-12 no-padding">
          <div className="card_content">
            <a href={props.item.ctaLink}>
              <h2>{props.item.title}</h2>
            </a>
            <hr className="dotted_divider_left_red" />
            <p>{props.item.desc.split(' ').length > 40 ? `${limitWords(props.item.desc, 40)}...` : props.item.desc}</p>

            <a href={props.item.ctaLink} className="btn btn-link-sabrage-sm">
              {props.item.ctaTitle} <i className="fas fa-long-arrow-alt-right" aria-hidden="true" />
            </a>
          </div>
        </div>
        <div className="col-md-12 no-padding">
          <div className="specials_img">
            <a href={props.item.ctaLink}>
              <img className="img-fluid mx-auto d-block" src={props.item.img} width="1000" height="1000" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default CardLarge;
