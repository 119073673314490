import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/hr';

import './Eventsticker.css';

const Eventsticker = ({ events }) => {
  moment.locale('hr');
  const today = new Date().getTime() / 1000;

  return (
    <div className="ticker-wrap">
      <div className="ticker">
        {events.filter(e => e.date.seconds >= today).map(event => (
          <Link className="ticker__item" to={`/dogadjanja/${event.id}`} key={event.id}>
            NAJAVA: {event.title} - {moment(event.date.toDate()).format('dddd, Do MMMM YYYY, HH:mm')}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Eventsticker;
