import React, { Component } from 'react';
import ajax from 'superagent';
import SanitizedHTML from 'react-sanitized-html';
import Moment from 'react-moment';
import { limitWords } from '../helpers/helpers';

class Specials extends Component {
  state = {
    latestNews: null,
  };

  componentWillMount() {
    this.getData();
  }

  getData() {
    ajax.get('https://www.trsatskagradina.com/wp-json/wp/v2/posts/?per_page=1&_embed').end((error, response) => {
      if (!error && response) {
        this.setState({ latestNews: response.body });
      } else {
        console.log('There was an error fetching data from TrsatskaGradina.com WP JSON API', error);
      }
    });
  }

  render() {
    const item = this.state.latestNews;
    const imageSource = '';
    // const imageSource = (item && item[0]._embedded['wp:featuredmedia']['0'].media_details.sizes.large.source_url) || '';

    return (
      <section id="specials">
        <div className="container">
          <div className="row">
            {item ? (
              <div className="col-xl-12">
                <div className="specials_wrap">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 no-padding align-self-center">
                      <div className="specials_content">
                        <a href={`/vijesti/${item[0].slug}`}>
                          <h2>{item[0].title.rendered}</h2>
                        </a>
                        <br />
                        {<Moment format="DD/MM/YYYY">{item[0].date}</Moment>}
                        <hr className="dotted_divider_left_red" />
                        <SanitizedHTML
                          html={
                            item[0].excerpt.rendered.split(' ').length > 23
                              ? `${limitWords(item[0].excerpt.rendered, 23)}...`
                              : item[0].excerpt.rendered
                          }
                        />

                        <a href={`/vijesti/${item[0].slug}`} className="btn btn-link-sabrage">
                          Više detalja <i className="fas fa-long-arrow-alt-right" aria-hidden="true" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 no-padding">
                      {
                        <a href={`/vijesti/${item[0].slug}`}>
                          {imageSource !== null ? (
                            <div
                              className="specials_img"
                              style={{
                                background: `url(${item[0]._embedded['wp:featuredmedia']['0'].source_url ||
                                  ''})no-repeat center center`,
                              }}
                            />
                          ) : null}
                        </a>
                      }
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              'Loading...'
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default Specials;
