import React from 'react';

import AboutCardLeft from './AboutCardLeft';
import AboutCardRight from './AboutCardRight';
import AboutCardTop from './AboutCardTop';
import AboutCardBottom from './AboutCardBottom';

const About = props => (
  <section id="about">
    <div className="container">
      <div className="about_title">
        <div className="row">
          <div className="col-lg-12">
            <h2>{props.site.header}</h2>
            <hr className="dotted_divider_center_red" />
          </div>
        </div>
      </div>

      <div className="about_content">
        <div className="row">
          <div className="col-md-6">
            <AboutCardLeft bar={props.site.onama[0]} />
            <AboutCardTop trsat={props.site.onama[1]} />
          </div>

          <div className="col-md-6">
            <AboutCardBottom gradina={props.site.onama[2]} />
            <AboutCardRight pozicija={props.site.onama[3]} />
          </div>
        </div>
      </div>
    </div>
  </section>
);
export default About;
