import React from 'react';
import CardLarge from './CardLarge';

import data from '../data/data.json';

const Onama = () => (
  <section id="special_orders">
    <div className="container">
      <div className="special_orders_content">
        <div className="row">{data.site.onama.slice(0, 4).map(item => <CardLarge item={item} key={item.slug} />)}</div>
      </div>
    </div>
  </section>
);

export default Onama;
