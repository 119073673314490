import React from 'react';
import { limitWords } from '../helpers/helpers';

const style = {
  backgroundColor: 'black',
  color: 'white',
};

const AboutCardRight = props => (
  <div className="about_card" style={style}>
    <div className="row no-padding">
      <div className="col-xl-6 col-lg-12 no-padding">
        <div className="specials_img">
          <img
            className="img-fluid mx-auto d-block"
            src="/images/_bg/sq-03.jpg"
            width="600"
            height="600"
            alt={props.pozicija.title}
          />
          />
        </div>
      </div>
      <div className="col-xl-6 col-lg-12 no-padding">
        <div className="card_content" style={style}>
          <h2>{props.pozicija.title}</h2>
          <hr className="dotted_divider_left_red" />
          <p>
            {props.pozicija.desc.split(' ').length > 22
              ? `${limitWords(props.pozicija.desc, 22)}...`
              : props.pozicija.desc}
          </p>
        </div>
      </div>
    </div>
  </div>
);
export default AboutCardRight;
