import React from 'react';
import SanitizedHTML from 'react-sanitized-html';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import moment from 'moment';
import 'moment/locale/hr';

// import data from '../data/data.json';

const style = {
  padding: '0 20px',
};

const EventPage = props => {
  const { event } = props;
  moment.locale('hr');

  return (
    <section id="blog_single">
      <div className="container">
        <div className="col-xl-10 offset-xl-1 col-md-12 col-sm-12">
          {event ? (
            <div className="card">
              <div className="card-header">
                <h2 className="card-title">{event.title}</h2>
                <h3 className="card-title">
                  {moment(event.date.toDate()).format('dddd, Do MMMM YYYY, HH:mm')} •{' '}
                  {moment(event.date.toDate()).fromNow()}
                </h3>
              </div>
              <img
                className="card-img-top img-fluid mx-auto"
                src={event.image || '/images/_events/sabrage-temp.jpg'}
                alt={event.title}
              />
              <div className="card-body">
                <div className="row">
                  <div className="card-text-big" style={style}>
                    <SanitizedHTML html={event.body} />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="card">
              <div className="card-header">
                <h2 className="card-title">Učitavam event...</h2>
              </div>
              <img className="card-img-top img-fluid mx-auto" src="/images/_events/sabrage-temp.jpg" alt="Loading" />
              <div className="card-body">
                <div className="row">
                  <div className="card-text-big" style={style}>
                    <p>Malo strpljenja...</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const events = state.firestore.data.events;
  const event = events ? events[id] : null;
  return {
    event,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: 'events' }])
)(EventPage);
