const initState = {
  events: [
    {
      id: '1',
      title: 'SuperCover Band',
      date: 'Petak, 2. studenog 2018.',
      timestamp: 1541199600,
      body:
        '<p><strong>SuperCover band</strong> je šesteročlani pop-rock-funky band sastavljen od vrhunskih muzičara profesionalaca sa bogatim glazbenim iskustvom.</p><p>Band je žanrovski raznovrstan, ali teži kvalitetnom repertoaru i isto takvoj zabavi!</p><p>Članovi benda:</p><ul><li>Domenik Tuna (prvi vokal, konge, truba, usna harmonika)</li><li>Borna Benko (vokal, gitara)</li><li>Andy Cech (vokal, bubnjevi)</li><li>Matko Basara(vokal, klavijature)</li><li>Eugen Srdoč (bass gitara, vokal)</li><li>Tonči Trinajstić (saksofon)</li></ul><p>Uz jedinstveni show program nude ne samo glazbenu atrakciju već i jedinstveni doživljaj i zabavu za svakoga.</p><p>Pozivamo vas da nam se pridružite u petak, 2. studenoga 2018., u caffe bar-u Sabrage uz fantastičnu glazbu, ugodan ambijent i širok odabir pića sa zabavnim osobljem.</p><p>INFO & REZERVACIJE STOLOVA NA TELEFON: 095 865 6677</p>',
      slug: '2018-supercover-band',
      image: '/images/_events/superband-01.jpg',
    },
    {
      id: '2',
      title: 'AnotherCover Band',
      date: 'Petak, 2. studenog 2018.',
      timestamp: 1541199600,
      body:
        '<p><strong>AnotherCover band</strong> je šesteročlani pop-rock-funky band sastavljen od vrhunskih muzičara profesionalaca sa bogatim glazbenim iskustvom.</p><p>Band je žanrovski raznovrstan, ali teži kvalitetnom repertoaru i isto takvoj zabavi!</p><p>Članovi benda:</p><ul><li>Domenik Tuna (prvi vokal, konge, truba, usna harmonika)</li><li>Borna Benko (vokal, gitara)</li><li>Andy Cech (vokal, bubnjevi)</li><li>Matko Basara(vokal, klavijature)</li><li>Eugen Srdoč (bass gitara, vokal)</li><li>Tonči Trinajstić (saksofon)</li></ul><p>Uz jedinstveni show program nude ne samo glazbenu atrakciju već i jedinstveni doživljaj i zabavu za svakoga.</p><p>Pozivamo vas da nam se pridružite u petak, 2. studenoga 2018., u caffe bar-u Sabrage uz fantastičnu glazbu, ugodan ambijent i širok odabir pića sa zabavnim osobljem.</p><p>INFO & REZERVACIJE STOLOVA NA TELEFON: 095 865 6677</p>',
      slug: '2018-anothercover-band',
      image: '/images/_events/superband-01.jpg',
    },
  ],
};

const eventReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CREATE_EVENT':
      // console.log('created event', action.event);
      return state;
    case 'CREATE_EVENT_ERROR':
      // console.log('created event error', action.err);
      return state;
    default:
      return state;
  }
};

export default eventReducer;
