import React from 'react';
import { limitWords } from '../helpers/helpers';

const AboutCardTop = props => (
  <div className="about_card">
    <div className="row no-padding">
      <div className="col-md-12 no-padding">
        <div className="specials_img">
          <a href={props.trsat.ctaLink}>
            <img
              className="img-fluid mx-auto d-block"
              src="/images/_bg/bg-01.jpg"
              width="600"
              height="600"
              alt={props.trsat.title}
            />
          </a>
        </div>
      </div>
      <div className="col-md-12 no-padding">
        <div className="card_content">
          <h2>{props.trsat.title}</h2>
          <hr className="dotted_divider_left_red" />
          <p>{props.trsat.desc.split(' ').length > 30 ? `${limitWords(props.trsat.desc, 30)}...` : props.trsat.desc}</p>

          <a href={props.trsat.ctaLink} className="btn btn-link-sabrage-sm">
            {props.trsat.ctaTitle}
            <i className="fas fa-long-arrow-alt-right" aria-hidden="true" />
          </a>
        </div>
      </div>
    </div>
  </div>
);
export default AboutCardTop;
