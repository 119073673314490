import React, { Component } from 'react';

import Navigation from './Navigation';
import Header from './Header';
import Footer from './Footer';

import data from '../data/data.json';

class Layout extends Component {
  state = {
    navigation: data.navigation,
    kontakt: data.kontakt,
  };

  render() {
    return (
      <div>
        <Navigation navigation={this.state.navigation} />
        <Header />
        {this.props.children}
        <Footer kontakt={this.state.kontakt} />
      </div>
    );
  }
}
export default Layout;
