import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { reduxFirestore, getFirestore } from 'redux-firestore';
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase';

import rootReducer from './store/reducers/rootReducer';

import db from './config/db.config'; // PRODUCTION ONLY
// import dbDevelopment from './config/db.dev.config'; // DEVELOPEMENT ONLY

import './css/app.css';
import AppRouter from './routes/';
import registerServiceWorker from './registerServiceWorker';

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(
      thunk.withExtraArgument({
        getFirebase,
        getFirestore,
      })
    ),
    reduxFirestore(db),
    reactReduxFirebase(db, { useFirestoreForProfile: true, userProfile: 'users', attachAuthIsReady: true })
  )
);

store.firebaseAuthIsReady.then(() => {
  ReactDOM.render(
    <Provider store={store}>
      <AppRouter />
    </Provider>,
    document.getElementById('root')
  );
  registerServiceWorker();
});
