import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/hr';

import './Event.css';

const EventModule = ({ event }) => {
  moment.locale('hr');

  return (
    <section id="events">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="event_wrap event-link">
              <Link to={`/dogadjanja/${event.id}`}>
                <img
                  src={event.image || '/images/_events/sabrage-temp.jpg'}
                  className="img-fluid mx-auto"
                  alt={event.title}
                />
                <h3 style={{ background: 'black', color: 'cyan' }}>
                  <span>NAJAVA: </span> {event.title} - {moment(event.date.toDate()).fromNow()}
                </h3>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventModule;
