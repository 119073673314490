import React from 'react';
import _ from 'lodash';

const images = [
  '/images/_bg/bg-03.jpg',
  '/images/_bg/bg-04.jpg',
  '/images/_bg/bg-05.jpg',
  '/images/_bg/bg-09.jpg',
  '/images/_bg/bg-10.jpg',
  '/images/_bg/bg-11.jpg',
  '/images/_bg/bg-12.jpg',
];

const img = _.sample(images);

const bnr01 = {
  background: 'center center no-repeat',
  backgroundImage: `url(${img})`,
};

const Header2 = () => (
  <header id="specials_header" style={bnr01}>
    <div className="container">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <div className="align-self-center">
            <div className="logo-badge">
              <a href="/">
                <img
                  src="/images/logo-sabrage.png"
                  width="300"
                  height="115"
                  className="img-fluid mx-auto d-block"
                  alt="Sabrage bar"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
);

export default Header2;
